 .Pages-nobullets {
  list-style-type: none;
 }

 .Pages-nounderlinelink {
    text-decoration:none;
 }

 .Pages-commonStyle {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 100vh;
   word-spacing: 3px;
   font-size: 20px;
   padding-left: 10%;
   padding-right: 10%;
 }